import { Box, Flex, Hide, Image, Show } from '@chakra-ui/react';
import styles from 'src/styles/layout/AppLayout.module.scss';
import cn from 'classnames';
import { icArrowDown, icClose, icMenu, icThreeDot } from 'src/assets/icon';
import { logoReact } from 'src/assets/images';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { useEffect } from 'react';
import { setCurrentAI } from 'src/store/chat';
import { EAIMode, ESource } from 'src/types';
import { Dropdown, MenuProps } from 'antd';
import { Select } from 'antd';
import { clearUser } from 'src/store/myAccount';
import { getNavigateRoute } from 'src/utils/chat';

type CProps = {
  openDrawer: boolean;
  onShowDrawer: () => void;
  onCloseDrawer: () => void;
};

interface IOptions {
  label: string;
  value: string;
  source: ESource;
}

const Header = ({ onShowDrawer, onCloseDrawer, openDrawer }: CProps) => {
  const { pathname } = useLocation();
  const { bot_id } = useParams();
  const navigate = useNavigate();
  const { currentAI, bots } = useAppSelector((state) => state.chat);
  const { user } = useAppSelector((state) => state.myAccount);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!bots?.length) return;
    if (!bot_id) {
      dispatch(setCurrentAI({ source: bots[0].source, bot_id: bots[0]._id }));
      return;
    }
    if (pathname.includes(EAIMode.AZURE_OPENAI)) {
      dispatch(setCurrentAI({ source: ESource.AZURE_OPENAI, bot_id: bot_id }));
    } else {
      dispatch(setCurrentAI({ source: ESource.CHATGPT, bot_id: bot_id }));
    }
  }, [pathname, bots, bot_id]);

  const handleChange = (bot_id: string, option: any) => {
    navigate(getNavigateRoute(option.source, bot_id));
    dispatch(setCurrentAI({ bot_id, source: option.source }));
  };

  const options: IOptions[] = bots?.map((el) => ({
    label: el.name,
    value: el._id,
    source: el.source,
  }));

  const menuItem: MenuProps['items'] = [
    {
      label: <Box w={150}>Log Out</Box>,
      key: '0',
      onClick: () => {
        dispatch(clearUser());
        navigate('/login');
      },
    },
  ];

  return (
    <Flex
      alignItems={'center'}
      px={{ base: 5, tablet: '50px' }}
      className={cn(styles.header)}
    >
      <Show breakpoint="(max-width: 1180px)">
        <Image
          width={'30px'}
          height={'30px'}
          onClick={openDrawer ? onCloseDrawer : onShowDrawer}
          src={openDrawer ? icClose : icMenu}
          mr={2}
          alt="menu"
        />
      </Show>
      <Hide breakpoint="(max-width: 1180px)">
        <Box cursor={'pointer'} onClick={() => navigate('/')}>
          <img
            src="/images/logo.png"
            alt="logo"
            className={cn(styles.headerLogo)}
          />
        </Box>
      </Hide>
      <Flex
        flex={1}
        justifyContent={'flex-start'}
        pl={{ base: 4, tablet: '100px' }}
      >
        <Box w={{ base: '200px', md: '240px' }}>
          <Select
            className={cn(styles.selectAI)}
            placeholder="Please select"
            value={currentAI.bot_id}
            onChange={handleChange}
            style={{ width: '100%', height: 30 }}
            suffixIcon={<Image w={5} src={icArrowDown} alt="arow" />}
            options={options}
          />
        </Box>
      </Flex>
      <Box className={cn(styles.info)}>
        <Flex
          className={cn(styles.btn_info)}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <button>
            <Image
              width={'30px'}
              height={'30px'}
              src={logoReact}
              mr={2}
              alt="AvatarChatbot"
            />

            <Hide breakpoint="(max-width: 1180px)">{user?.email}</Hide>
          </button>
          <Dropdown menu={{ items: menuItem }} trigger={['click']}>
            <Box cursor={'pointer'} onClick={(e) => e.preventDefault()}>
              <Image ml={2} src={icThreeDot} alt="icThreeDot" />
            </Box>
          </Dropdown>
        </Flex>
      </Box>
    </Flex>
  );
};
export default Header;
